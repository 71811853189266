<template>
    <div class="mx-3">
      <v-menu offset-y> 
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="blue" class="mb-3 mt-3" dark v-bind="attrs" v-on="on">
           {{ $t('Add New Document') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="itemClicked(item)"
            style="cursor: pointer"
            v-for="item in items"
            :key="item.id"
          >
            <v-list-item-title>{{
              documentTypeFormatter(item)
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="filters.id"
            label="ID"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="filters.serial"
            :label="$t('Document Number') "
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete
            v-model="filters.document_type_name"
            :label="$t('Document Name') "
            dense
            item-text="name"
            item-value="name"
            outlined
            :items="items"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-autocomplete
            v-model="filters.document_type"
            :label="$t('Document Type')"
            dense
            item-text="documentArLabel"
            item-value="type"
            outlined
            :items="items"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-autocomplete
              :label="$t('cost center')"
              :items="costCenterItems"
              color="#757575"
              item-text="name"
              item-value="id"
              :loading="costCenterLoading"
              v-model="filters.cost_center"
              @keypress="fetchAutoCompleteCostCenter($event)"
              dense
              outlined
            >
            </v-autocomplete>
        </v-col>
        
        <v-col md="3">
          <v-text-field
            v-model="filters.notes"
            :label="$t('notes')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" outlined
            dense >
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" outlined
            dense>
          </v-text-field>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
      </v-row>
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1"
        hide-default-footer
        :items-per-page="10">
        <template v-slot:item.actions="{ item }">
          <td style="width: 10%;">
            <v-btn @click="documentTypeToHandler(item)" color="blue" icon>
              <v-icon class="ml-1">mdi-pen</v-icon>
            </v-btn>
            <deleteDocument :id="item.id" @record-deleted="getAll" />
          </td>        
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && items.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import deleteDocument from "./deleteDocument.vue";
import i18n from "../../../../i18n";
  export default {
    components: { deleteDocument },
    data: () => ({
      types: [
        {
          label: "يومية صندوق",
          value: "DAILY",
        },
      
      ],
      costCenterItems: [],
      costCenterLoading: false,
      tableItems: [],
      documentTypes: [],
      loading: false,
      page: 1,
      total: 1,
      headers: [
        { text: "ID", value: "id" },
        { text: i18n.t('Document Number'), value: "serial" },
        { text: i18n.t('Document Name'), value: "voucher_type.name" },
        { text: i18n.t('date'), value: "date" },
        { text: i18n.t('Document Type'), value: "voucher_type.type" },
        { text: i18n.t('total debit'), value: "debit" },
        { text: i18n.t('total credit'), value: "credit" },
        { text: i18n.t('notes'), value: "notes" },
        { text: "", value: "actions" },
      ],
      items: [],
      filters: {
        from: null,
        to: null,
        id: null,
        serial: null,
        document_type_name: null,
        document_type: null,
        client_name: null,
        paid_amount: null,
        total_amount: null,
        cost_center: null,
        notes: null,
      },
    }),
    methods: {
      clearFilter() {
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.id = undefined;
        this.filters.serial = undefined;
        this.filters.document_type_name = undefined;
        this.filters.document_type = undefined;
        this.filters.client_name = undefined;
        this.filters.paid_amount = undefined;
        this.filters.total_amount = undefined;
        this.filters.notes = undefined;
        this.filters.cost_center = undefined;

        this.page = 1;
        this.getAll();
      },
      documentTypeToHandler(item) {
       
          this.$router.push(
            `edit-document/${item.id}?document_id=${item.voucher_type.id}`
          );
      
      },
      async getAll(page) {
        try {
          if (!page) this.page = 1;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          const res = await axios.get("/accounting/voucher", {
            params: {
              size: 10,
              page: this.page,
              ...filterTemp,
            },
          });
          this.tableItems = res.data.data.map((el) => {
            const typeTemp = this.types.find(
              (typ) => el?.document_type?.type == typ.value
            );
            return {
              ...el,
              documentTypeName: el?.document_type?.name,
              clientAccountName: el?.client_account?.name,
              type: typeTemp ? typeTemp.label : " ",
              documentStatus: el.is_locked ? "Locked" : "UnLocked",
            };
          });
  
          this.total = res.data.meta.total;
        } finally {
          this.loading = false;
        }
      },
      documentTypeFormatter(documentType) {
        const documentTemp = [
          {
            label: "يومية صندوق",
            value: "DAILY",
          },
         
        ].find((el) => el.value == documentType?.type);
        let str = "";
        if (documentTemp) {
          str = ` ${documentType.name}`;
        }
        return str;
      },
      itemClicked(v) {
       
          this.$router.push(`/new-document?document_id=${v.id}`);
      
      },
      documentTypeLabelFormatter(type) {
        return [
          {
            label: "يومية صندوق",
            value: "DAILY",
          },
          
        ].find((el) => el.value == type).label;
      },
      async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterLoading = false;
        }
      }, 500)();
    },
      async getdocumentTypes() {
        const res = await axios.get("/accounting/voucher-type");
        this.items = res.data.data.map((el) => ({
          ...el,
          documentArLabel: this.documentTypeLabelFormatter(el.type),
        }));
        console.log(this.items);
      },
    },
    created() {
      
      this.getdocumentTypes();
    },
  };
  </script>
  
  <style></style>
  